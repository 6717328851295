import { CircularProgress } from '@mui/material'

import './index.css'

const sizeMap = {
    small: 24,
    default: 36,
    large: 64
}

const SpinnerComponent = ({ config={} }) => {
    const decorateProps = () => {
        return {
            size: sizeMap[config.size || 'default']
        }
    }

    return (
        <div className="c-Spinner">
            <CircularProgress {...decorateProps()} />
        </div>
    )
}

export { SpinnerComponent }