import './index.css'

const ListComponent = ({ value, config={}, events={} }) => {
    const getTrimmedTitle = (str) => {
        if (str.length < 30) {
            return str
        } else {
            return `${str.slice(0, 30).trim()}...`   
        }
    }

    const getTheme = (item) => {
        if (item.deleteDate) {
            return 'palette-gray'
        } else if (item.deadDate) {
            return 'palette-red'
        } else if (item.clicksDead > 0) {
            return 'palette-yellow'
        }
    }

    const getDate = (item) => {
        return `Date: ${item.date}`
    }

    return (
        <div className="c-List">
            {value.map((item, index) => {
                return (
                    <div className={`list-row ${getTheme(item)}`} key={`list-row-item-${item.id}`}>
                        <div className="list-rowContainer">
                            <div className="list-rowImage" style={{ backgroundImage: `url(${item.imageUrl})` }}></div>

                            <div className="list-rowMain">
                                <div className="list-rowAffiliate">
                                    {item.affiliate.name}
                                </div>

                                <div className="list-rowTitle">
                                    {getTrimmedTitle(item.name)}
                                </div>

                                <div className="list-rowControls">
                                    {config.controls(item)}
                                </div>
                            </div>
                        </div>

                        <div className="list-rowDetails">
                            <strong className="list-rowDetailsDate">{getDate(item)}</strong> <span>Clicks: {item.clicks} / Dead Clicks: {item.clicksDead}</span>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export { ListComponent }