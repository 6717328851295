import { useEffect } from 'react'

import { FetchHelper } from '../../helpers/fetch'

import { useSimpleState } from '../useSimpleState'

const useSession = () => {
    const {_state} = useSimpleState({
        fetching: false,
        fetched: false,
        user: {},
        config: {}
    })

    const redirectToLogin = sessionStorage.getItem('redirectToLogin')

    const getUser = () => {
        return JSON.parse(sessionStorage.getItem('user')) || {}
    }

    useEffect(() => {
        if (_state.get('fetching'))
            return

        fetchProfile()
    }, [])

    useEffect(() => {
        if (redirectToLogin) {
            sessionStorage.removeItem('user')
            sessionStorage.removeItem('redirectToLogin')

            window.location = '/'
        }
    }, [redirectToLogin])

    const fetchProfile = () => {
        _state.set('fetching', true)

        let user = getUser()

        if (!user.id) {
            _state.set('fetching', false)
            _state.set('fetched', true)

            return
        }

        FetchHelper({
            url: `/api/v1/profile`
        }).then((res) => {
            _state.set('user', res.body)
        }).catch((res) => {
            
        }).finally(() => {
            _state.set('fetching', false)
            _state.set('fetched', true)
        })
    }

    return {
        Session: {
            fetched: _state.get('fetched'),
            user: _state.get('user'),
            config: _state.get('config')
        }
    }
}

export { useSession }