import { cloneElement } from 'react'

import { useSimpleState } from '../useSimpleState'

const View = (props, getCurrentView) => {
    let cv = getCurrentView()

    return cloneElement(cv, {
        ...props,
        className: `${cv.props.className || ''} ${props.className}`,
    })
}

const useView = ({ initialView, views }) => {
    const {_state} = useSimpleState({
        active: initialView,
        next: null,
        animationComplete: false
    })

    const set = (view) => {
        if (!view)
            return

        _state.set('next', view)
    }

    const changeView = (next) => {
        _state.set('animationComplete', false)
        _state.set('next', null)
        _state.set('active', next)
    }

    const getCurrentView = () => {
        return views[_state.get('active')]()
    }

    const get = () => {
        let viewProps = {}

        if (_state.get('next')) {
            viewProps = { className: "animation-fadeOut disabled", onAnimationEnd: () => changeView(_state.get('next')) }
        } else {
            viewProps = { className: _state.get('animationComplete') ? "" : "animation-fadeIn disabled", onAnimationEnd: () => _state.set('animationComplete', true) }
        }

        return View(viewProps, getCurrentView)
    }

    return {
        _view: {
            set,
            get,
            active: () => _state.get('active')
        }
    }
}

export { useView }