import { useNavigate } from 'react-router-dom'

import { LayoutPage } from '../LayoutPage'

import './index.css'

const NavigationBarComponent = () => {
    const navigate = useNavigate()

    const handleClickManageDeals = () => {
        navigate('/')
    }

    const handleClickCreateDeals = () => {
        navigate('/create-deals')
    }

    const handleClickLogout = () => {
        navigate('/logout')
    }

    return (
        <div className="c-NavigationBar">
            <LayoutPage>
                <ul className="navigationBar-navMain">
                    <li onClick={handleClickManageDeals}>Manage Deals</li>
                    <li onClick={handleClickCreateDeals}>Create Deals</li>
                    {/* <li>Manage Affiliates</li> */}
                </ul>

                <ul className="navigationBar-navUser">
                    <li onClick={handleClickLogout}>Logout</li>
                </ul>
            </LayoutPage>
        </div>
    )
}

export { NavigationBarComponent }