import { useContext } from 'react'

import { AppContext } from './context'

export default function useAppContext() {
    const ac = useContext(AppContext)

    return { ac }
}

export { useAppContext }