import PropTypes from 'prop-types'

import './index.css'

const FieldErrorComponent = ({ errors, name, config={}, events={} }) => {
    if (!errors || !name) {
        return
    }

    const error = errors[name]

    if (!error)
        return

    return (
        <div className={`c-FieldError ${config.classNames || ''} animation-fadeIn`}>
            <div className="error">
                {error.map((item, index) => {
                    return (
                        <div className="error-item" key={`fieldError-errorItem-${item}`}>
                            {item}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

FieldErrorComponent.propTypes = {
    config: PropTypes.shape({
        classNames: PropTypes.string
    }),

    events: PropTypes.shape({

    })
}

export { FieldErrorComponent }