import { Link, useNavigate } from 'react-router-dom'

import { ButtonComponent } from '../../components/Button'
import { SpinnerComponent } from '../../components/Spinner'

import { FetchHelper } from '../../helpers/fetch'

import { useSimpleState } from '../../hooks/useSimpleState'

import './index.css'

const LoginPage = ({ }) => {
    const navigate = useNavigate()

    const {_state} = useSimpleState({
        fetching: false
    })

    const {_state: _form} = useSimpleState({
        email: '',
        password: ''
    })

    const {_state: _errors} = useSimpleState({})

    const handleLogin = () => {
        _state.set('fetching', true)
        _errors.set()

        let data = {
            email: _form.get('email'),
            password: _form.get('password')
        }

        FetchHelper({
            url: '/api/v1/login',
            options: {
                method: 'post',
                data: data
            }
        }).then((res) => {
            if (res.status === 200) {
                storeUser(res)
            } else {
                _errors.set('main', 'Login failed. Try again.')
                _state.set('fetching', false)
            }
        }).catch((res) => {
            _state.set('fetching', false)
        }).finally(() => {

        })
    }

    const storeUser = (res) => {
        let data = {
            id: res.body.id,
            token: res.body.token
        }

        sessionStorage.setItem('user', JSON.stringify(data))

        window.location = '/'
    }

    const handleFormKeyUp = (e) => {
        if (e.which === 13)
            handleLogin()
    }

    return (
        <div className={`p-Login ${_state.get('fetching') ? 'disabled' : ''}`}>
            <div className="login-form">
                <div className="login-formContainer">
                    <div className="form-field">
                        <label htmlFor="name">Email</label>
                        
                        <input
                            value={_form.get('email')}
                            onChange={(e) => _form.set('email', e.target.value)}
                            onKeyUp={handleFormKeyUp} />
                    </div>

                    <div className="form-field">
                        <label htmlFor="name">Password</label>

                        <input
                            value={_form.get('password')}
                            type="password"
                            onChange={(e) => _form.set('password', e.target.value)}
                            onKeyUp={handleFormKeyUp} />
                    </div>

                    {_state.get('fetching') ? <SpinnerComponent config={{ size: 'small' }} /> : null}

                    <div className="form-controls">
                        <ButtonComponent events={{
                            handleClick: handleLogin
                        }}>
                            Login
                        </ButtonComponent>
                    </div>
                </div>
            </div>

            <div className="login-image"></div>
        </div>
    )
}

export { LoginPage }