import { useState } from 'react'

const useSimpleState = (defaultState={}) => {
    const [_value, _setValue] = useState(defaultState)

    const get = (key) => {
        if (!key)
            return _value
        else if (key && typeof _value === 'object' && _value.hasOwnProperty(key))
            return _value[key]
    }

    const set = (key, value) => {
        if (typeof key === 'string' && typeof _value === 'object') {
            _setValue(prev => ({
                ...prev,
                [key]: value
            }))
        } else {
            _setValue(key)
        }   
    }

    return {
        _state: { get, set }
    }
}

export { useSimpleState }