import { useEffect } from 'react'

import PropTypes from 'prop-types'

import { FetchHelper } from '../../helpers/fetch'

import { useAppContext } from '../useAppContext'
import { useSimpleState } from '../useSimpleState'

import dayjs from 'dayjs'

const useControlSearch = (props={}) => {
    const {ac} = useAppContext()

    const {_state} = useSimpleState({})

    const {_state: _params} = useSimpleState(props.params || '')

    const {_state: _results} = useSimpleState()

    const fetch = () => {
        if (props.beforeFetch)
            props.beforeFetch()

        return FetchHelper({
            url: _params.get() !== '' ? `/api/v1/deals-admin?${_params.get()}` : `/api/v1/deals-admin`
        })
            .then((res) => {
                if (res.status === 200) {
                    _results.set(res)
                }
            })
            .catch((res) => {
                console.log('control search fetch error', res)
            })
            .finally(() => {
                if (props.afterFetch)
                    props.afterFetch()
            })
    }

    const fetchGetDeal = (id) => {
        if (props.beforeFetch)
            props.beforeFetch()

        return FetchHelper({
            url: `/api/v1/deals/${id}`
        })
            .then((res) => {
                return res
            })
            .catch((res) => {
                return res
            })
            .finally(() => {
                if (props.afterFetch)
                    props.afterFetch()
            })
    }
    
    const fetchCreate = (body) => {
        if (props.beforeFetch)
            props.beforeFetch()

        return FetchHelper({
            url: '/api/v1/deals/post',
            options: {
                method: 'post',
                data: body
            }
        })
            .then((res) => {
                return res
            })
            .catch((res) => {
                return res
            })
            .finally(() => {
                if (props.afterFetch)
                    props.afterFetch()
            })
    }

    const fetchEdit = (body) => {
        if (props.beforeFetch)
            props.beforeFetch()

        return FetchHelper({
            url: '/api/v1/deals/update',
            options: {
                method: 'put',
                data: body
            }
        })
            .then((res) => {
                return res
            })
            .catch((res) => {
                return res
            })
            .finally(() => {
                if (props.afterFetch)
                    props.afterFetch()
            })
    }

    const fetchPublish = (item, status) => {
        if (props.beforeFetch)
            props.beforeFetch()

        return FetchHelper({
            url: '/api/v1/deals/publish',
            options: {
                method: 'put',
                data: {
                    id: item.id,
                    publish: status
                }
            }
        })
            .then((res) => {
                if (res.status === 200) {
                    fetch()
                }

                return res
            })
            .catch((res) => {
                return res
            })
            .finally(() => {
                if (props.afterFetch)
                    props.afterFetch()
            })
    }

    const fetchArchive = (item, status) => {
        if (props.beforeFetch)
            props.beforeFetch()

        return FetchHelper({
            url: '/api/v1/deals/archive',
            options: {
                method: 'put',
                data: {
                    id: item.id,
                    archive: status
                }
            }
        })
            .then((res) => {
                if (res.status === 200) {
                    fetch()
                }

                return res
            })
            .catch((res) => {
                return res
            })
            .finally(() => {
                if (props.afterFetch)
                    props.afterFetch()
            })
    }

    const fetchDead = (item, status) => {
        if (props.beforeFetch)
            props.beforeFetch()

        return FetchHelper({
            url: '/api/v1/deals/dead',
            options: {
                method: 'put',
                data: {
                    id: item.id,
                    dead: status
                }
            }
        })
            .then((res) => {
                if (res.status === 200) {
                    fetch()
                }

                return res
            })
            .catch((res) => {
                return res
            })
            .finally(() => {
                if (props.afterFetch)
                    props.afterFetch()
            })
    }

    const fetchDelete = (item, status) => {
        if (props.beforeFetch)
            props.beforeFetch()

        return FetchHelper({
            url: '/api/v1/deals/delete',
            options: {
                method: 'put',
                data: {
                    id: item.id,
                    delete: status
                }
            }
        })
            .then((res) => {
                if (res.status === 200) {
                    fetch()
                }
                
                return res
            })
            .catch((res) => {
                return res
            })
            .finally(() => {
                if (props.afterFetch)
                    props.afterFetch()
            })
    }

    useEffect(() => {
        fetch()
    }, [_params.get()])

    return Object.freeze({
        _results,
        _params,
        _control: {
            fetch,
            fetchGetDeal,
            fetchEdit,
            fetchCreate,
            fetchPublish,
            fetchArchive,
            fetchDead,
            fetchDelete,
        }
    })
}

useControlSearch.propTypes = {
    beforeFetch: PropTypes.func,
    afterFetch: PropTypes.func,
}

export { useControlSearch }