import { FieldErrorComponent } from '../FieldError'

import { useSimpleState } from '../../hooks/useSimpleState'
import { ButtonComponent } from '../Button'
import { PickerDateComponent } from '../PickerDate'

import dayjs from 'dayjs'

import './index.css'

const CreateDealFormComponent = ({ value={}, config={}, events={} }) => {
    const {_state} = useSimpleState({
        affiliateId: value.affiliateId || 1,
        url: value.url || '',
        name: value.name || '',
        description: value.description || '',
        date: value.date || dayjs().format('YYYY-MM-DD HH:mm:ss'),
        priceOriginal: value.priceOriginal || '',
        priceFinal: value.priceFinal || '',
        imageUrl: value.imageUrl || '',

        couponRedeemableDescription: value.couponRedeemableDescription || '',
        couponPromoCode: value.couponPromoCode || ''
    })

    const {_state: _clickableCouponState} = useSimpleState({
        selected: getClickableCouponStateSelection(),
        couponClickablePercentage: value.couponClickablePercentage || '',
        couponClickableAmount: value.couponClickableAmount || ''
    })

    function getClickableCouponStateSelection() {
        if (value.couponClickablePercentage) {
            return 1
        } else if (value.couponClickableAmount) {
            return 2
        } else {
            return 0
        }
    }

    const handleAffiliateChange = (e) => {
        _state.set('affiliateId', e.target.value)
    }

    const handleUrlChange = (e) => {
        _state.set('url', e.target.value)
    }

    const handleNameChange = (e) => {
        _state.set('name', e.target.value)
    }

    const handleDescriptionChange = (e) => {
        _state.set('description', e.target.value)
    }

    const handleDateChange = (val) => {
        _state.set('date', val)
    }

    const handleOriginalPriceChange = (e) => {
        _state.set('priceOriginal', filterNumber(e.target.value))
    }

    const handleFinalPriceChange = (e) => {
        _state.set('priceFinal', filterNumber(e.target.value))
    }

    const handleImageUrlChange = (e) => {
        _state.set('imageUrl', e.target.value)
    }

    const handleClickableCouponChange = (val) => {
        _clickableCouponState.set('selected', val)
    }

    const handleClickableCouponPercentageChange = (e) => {
        _clickableCouponState.set('couponClickablePercentage', filterNumber(e.target.value))
    }

    const handleClickableCouponDollarsChange = (e) => {
        _clickableCouponState.set('couponClickableAmount', filterNumber(e.target.value))
    }

    const handleCouponRedeemableDescriptionChange = (e) => {
        _state.set('couponRedeemableDescription', e.target.value)
    }

    const handleCouponPromoCodeChange = (e) => {
        _state.set('couponPromoCode', e.target.value)
    }

    const filterNumber = (val) => {
        return val.replace(/[^0-9\.]+/, '')
    }

    const handleSubmit = () => {
        let body = _state.get()

        switch (_clickableCouponState.get('selected')) {
            case 1: 
                body.couponClickablePercentage = _clickableCouponState.get('couponClickablePercentage')
                break
            case 2: 
                body.couponClickableAmount = _clickableCouponState.get('couponClickableAmount')
                break
        }

        if (config.actionType === 'edit') {
            body.id = value.id
        }

        if (events.handleSubmit)
            events.handleSubmit(body)
    }

    return (
        <div className="c-CreateDealForm">
            <div className="createDealForm-sectionTitle">
                General
            </div>

            <div className="form-fields">
                <div className="form-field">
                    <label>Affiliate ID</label>

                    <input type="text" value={_state.get('affiliateId')} onChange={handleAffiliateChange} />

                    <FieldErrorComponent errors={config.errors} name="affiliateId" />
                </div>

                <div className="form-field">
                    <label>URL</label>

                    <input type="text" value={_state.get('url')} onChange={handleUrlChange} />

                    <FieldErrorComponent errors={config.errors} name="url" />
                </div>

                <div className="form-field">
                    <label>Name</label>

                    <input type="text" value={_state.get('name')} onChange={handleNameChange} />

                    <FieldErrorComponent errors={config.errors} name="name" />
                </div>

                <div className="form-field">
                    <label>Description</label>

                    <input type="text" value={_state.get('description')} onChange={handleDescriptionChange} />

                    <FieldErrorComponent errors={config.errors} name="description" />
                </div>

                <div className="form-field">
                    <label>Date</label>

                    <PickerDateComponent value={_state.get('date')} events={{ handleChange: handleDateChange }} />

                    <FieldErrorComponent errors={config.errors} name="date" />
                </div>

                <div className="form-field">
                    <label>Original Price</label>

                    <input type="text" value={_state.get('priceOriginal')} onChange={handleOriginalPriceChange} />

                    <FieldErrorComponent errors={config.errors} name="priceOriginal" />
                </div>

                <div className="form-field">
                    <label>Final Price</label>

                    <input type="text" value={_state.get('priceFinal')} onChange={handleFinalPriceChange} />

                    <FieldErrorComponent errors={config.errors} name="priceFinal" />
                </div>

                <div className="form-field">
                    <label>Image</label>

                    <input type="text" value={_state.get('imageUrl')} onChange={handleImageUrlChange} />

                    {_state.get('imageUrl') ? (
                        <div className="createDealForm-image" style={{ backgroundImage: `url(${_state.get('imageUrl')})`}}></div>
                    ) : null}

                    <FieldErrorComponent errors={config.errors} name="imageUrl" />
                </div>

                <div className="form-field">
                    <label>Clickable Coupon</label>

                    <div className="form-fieldSection">
                        <input type="radio" value={0} checked={_clickableCouponState.get('selected') === 0} onChange={() => handleClickableCouponChange(0)} /> None
                    </div>

                    <div className="form-fieldSection">
                        <input type="radio" value={1} checked={_clickableCouponState.get('selected') === 1} onChange={() => handleClickableCouponChange(1)} /> Percentage

                        <div className="form-fieldSection">
                            <input type="text" value={_clickableCouponState.get('couponClickablePercentage')} onChange={handleClickableCouponPercentageChange} />
                        </div>

                        <FieldErrorComponent errors={config.errors} name="couponClickablePercentage" />
                    </div>

                    <div className="form-fieldSection">
                        <input type="radio" value={2} checked={_clickableCouponState.get('selected') === 2} onChange={() => handleClickableCouponChange(2)} /> Dollars

                        <div className="form-fieldSection">
                            <input type="text" value={_clickableCouponState.get('couponClickableAmount')} onChange={handleClickableCouponDollarsChange} />
                        </div>

                        <FieldErrorComponent errors={config.errors} name="couponClickableAmount" />
                    </div>
                </div>

                <div className="form-field">
                    <label>Redeemable Coupon Description</label>

                    <input type="text" value={_state.get('couponRedeemableDescription')} onChange={handleCouponRedeemableDescriptionChange} />

                    <FieldErrorComponent errors={config.errors} name="couponRedeemableDescription" />
                </div>

                <div className="form-field">
                    <label>Promo Code</label>

                    <input type="text" value={_state.get('couponPromoCode')} onChange={handleCouponPromoCodeChange} />

                    <FieldErrorComponent errors={config.errors} name="couponPromoCode" />
                </div>
            </div>

            <div className="form-controls">
                <ButtonComponent events={{
                    handleClick: handleSubmit
                }}>
                    {config.actionType === 'edit' ? 'Save' : 'Create'}
                </ButtonComponent>
            </div>
        </div>
    )
}

export { CreateDealFormComponent }