import PropTypes from 'prop-types'

import './index.css'

const LayoutPage = ({ value={}, children, config={} }) => {
    return (
        <div className={`c-LayoutPage ${config.id ? `p-${config.id}` : ''} ${config.classNames || ''}`}>
            {children}
        </div>
    )
}

LayoutPage.propTypes = {
    children: PropTypes.any,

    config: PropTypes.shape({

    })
}

export { LayoutPage }