import './index.css'

const ButtonComponent = ({ children, config={}, events={} }) => {
    const handleClick = () => {
        if (events.handleClick)
            events.handleClick()
    }

    const getPalette = () => {
        return `palette-${config.palette || 'blue'}`
    }

    return (
        <button className={`c-Button ${getPalette()}`} onClick={handleClick}>{children}</button>
    )
}

export { ButtonComponent }