import PropTypes from 'prop-types'

import './index.css'

const TabsComponent = ({ value, options, config={}, events={} }) => {
    if (value === undefined || !options)
        return

    const handleChange = (index) => {
        if (events.handleChange)
            events.handleChange(index)
    }

    const getTabs = () => {
        return options.map((item, index) => {
            return (
                <div className={`tab ${index === value ? 'active' : ''}`} key={JSON.stringify(item)} onClick={() => handleChange(index)}>
                    {item.title}
                </div>
            )
        })
    }

    return (
        <div className={`c-Tabs type-${config.type || 'compact'}`}>
            {getTabs()}
        </div>
    )
}

TabsComponent.propTypes = {
    value: PropTypes.number,

    options: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.any
    })),

    config: PropTypes.shape({
        type: PropTypes.oneOf(['compact', 'simple'])
    }),

    events: PropTypes.shape({
        handleChange: PropTypes.func
    })
}

export { TabsComponent }