import { useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import { ButtonComponent } from '../../components/Button'
import { CreateDealFormComponent } from '../../components/CreateDealForm'
import { LayoutPage } from '../../components/LayoutPage'
import { PageTitleComponent } from '../../components/PageTitleComponent'
import { SpinnerComponent } from '../../components/Spinner'

import { useAppContext } from '../../hooks/useAppContext'
import { useControlSearch } from '../../hooks/useControlSearch'
import { useSimpleState } from '../../hooks/useSimpleState'
import { useView } from '../../hooks/useView'

const EditDealPage = () => {
    const { id } = useParams()

    const navigate = useNavigate()

    const { _state } = useSimpleState({
        fetching: false,
        deal: null
    })

    const {_state: _errors} = useSimpleState({})

    const {_view: _viewMain} = useView({
        initialView: 'loading',
        views: {
            loading: renderLoading,
            main: renderMain
        }
    })

    const {_control, _results, _params} = useControlSearch({
        beforeFetch: () => {},
        afterFetch: () => {}
    })

    const fetch = () => {
        _control.fetchGetDeal(id).then((res) => {
            if (res.status === 200) {
                _state.set('deal', res.body)
            } else {
                navigate('/')   
            }
        }).catch((res) => {
            navigate('/')
        }).finally(() => {
            _viewMain.set('main')
        })
    }

    useEffect(() => {
        fetch()
    }, [])

    const handleSubmit = (body) => {
        _state.set('fetching', true)

        _control.fetchEdit(body).then(res => {
            if (res.status === 200) {
                navigate('/')
            } else {
                _errors.set(res.errors.errors)
            }
        }).catch(res => {
            
        }).finally(() => {
            _state.set('fetching', false)
        })
    }

    function renderLoading() {
        return (
            <div>
                <SpinnerComponent />
            </div>
        )
    }

    function renderMain() {
        return (
            <div>
                <LayoutPage config={{ id: 'ManageDeals' }}>
                    <PageTitleComponent value="Create Deals" />

                    <div className={_state.get('fetching' ? 'disabled' : '')}>
                        <CreateDealFormComponent value={_state.get('deal')} config={{
                            errors: _errors.get(),
                            actionType: 'edit'
                        }} events={{
                            handleSubmit: handleSubmit
                        }} />
                    </div>
                </LayoutPage>
            </div>
        )
    }

    return _viewMain.get()
}

export { EditDealPage }