import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const ScrollToTop = ({ children }) => {
    const location = useLocation()

    useEffect(() => {
        document.querySelector("html").scrollTo(0,0)
    }, [location.pathname])

    return children
}

export { ScrollToTop }