import { useEffect } from 'react'

const LogoutPage = () => {
    useEffect(() => {
        handleLogout()
    }, [])

    const handleLogout = () => {
        sessionStorage.removeItem('user')

        window.location = '/'
    }

    return null
}

export { LogoutPage }