import { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { NavigationBarComponent } from './components/NavigationBar'
import { ScrollToTop } from './components/ScrollToTop'
import { SpinnerComponent } from './components/Spinner'

import { CreateDealsPage } from './pages/CreateDeals'
import { EditDealPage } from './pages/EditDeal'
import { LoginPage } from './pages/Login'
import { LogoutPage } from './pages/Logout'
import { ManageDealsPage } from './pages/ManageDeals'

import { useSession } from './hooks/useSession'
import { useSimpleState } from './hooks/useSimpleState'
import { useView } from './hooks/useView'

import { AppProvider } from './hooks/useAppContext/context'

import './styles/main.css'

function App() {
    const { Session } = useSession()

    const { _state } = useSimpleState({})

    const {_view} = useView({
        initialView: 'loading',
        views: {
            loading: renderLoading,
            main: renderMain
        }
    })

    useEffect(() => {
      if (Session.fetched && _view.active() === 'loading')
          _view.set('main')
  }, [Session.fetched])

    function renderLoading() {
        return (
            <div className="app-container">
                <SpinnerComponent />
            </div>
        )
    }

    function renderMain() {
        return (
            <div className="app-container">
                <AppProvider value={{ _state }}>
                    <BrowserRouter>
                        <ScrollToTop>
                            {Session.user?.id ? (
                                <>
                                    <NavigationBarComponent />
                                    <Routes>
                                        <Route path="/" element={<ManageDealsPage />} />
                                        <Route path="/create-deals" element={<CreateDealsPage />} />
                                        <Route path="/edit-deal/:id" element={<EditDealPage />} />
                                        <Route path="/logout" element={<LogoutPage />} />
                                    </Routes>
                                </>
                            ) : (
                                <Routes>
                                    <Route path="/" element={<LoginPage />} exact />
                                </Routes>
                            )}
                        </ScrollToTop>
                    </BrowserRouter>
                </AppProvider>
            </div>
        )
    }

    return _view.get()
}

export default App;
